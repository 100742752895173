/**
 * Podstawowe style
 */

html{
  font-size: 62.5%;
}

body{
  background: $bg-color;
  color: $text-color;
  font-family: $font-family;
  line-height: 1.2;
  @include font-size(22);
  background-image: linear-gradient(to top, #e2ecf2 0%, #e2ebf0 100%);

}

a{
  &, &:active, &:visited{
    text-decoration: none;
    color: $color-blue;
  }
}

p{
  line-height: 1.5;
}

img{
  max-width: 100%;
}

// dla najmniejszego mobile'a ustawiamy gutter na 20px
@include media-breakpoint-down(sm){
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }

  .row{
    margin-left: -20px;
    margin-right: -20px;

    > .col,
    > [class*="col-"] {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}