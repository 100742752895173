/**
 * Funkcje wykorzystywane w pozostałych plikach
 */

@mixin font-size($sizeValue: 16) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 10) + rem;
}

@function unfoldTransition($transition, $prefix: null) {
  // Default values
  $property: all;
  $duration: $transition-default-duration;
  $easing: $transition-default-easing;
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i);
      @if ($prefix and $p == 'transform') {
        $p: #{$prefix}#{$p};
      }
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Pass in any number of transitions
@mixin transition($transitions...) {
  $webkitUnfoldedTransitions: ();
  $unfoldedTransitions: ();
  $willChange: ();
  @each $transition in $transitions {
    $webkitUnfoldedTransitions: append($webkitUnfoldedTransitions, unfoldTransition($transition, '-webkit-'), comma);
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
    $willChange: append($willChange, nth($transition, 1), comma);
  }

  transition: $unfoldedTransitions;
  will-change: $willChange;
}

// @example
// @include x-multiple-colored-gradient(('top', #fff 0%, #aaa 50%, #000 100%));
@mixin x-multiple-colored-gradient($args) {

  $gradient: ();
  $pos: nth($args, 1);
  $pos_newsyntax: ();

  @if ($pos != 'top')
		and ($pos != 'right')
		and ($pos != 'bottom')
		and ($pos != 'left')
		and ($pos != 'to top')
		and ($pos != 'to right')
		and ($pos != 'to bottom')
		and ($pos != 'to left') {
    $pos: 'top';
  }

  // New Syntax
  @if $pos == 'top' {
    $pos_newsyntax: 'to bottom';
  } @else if $pos == 'right' {
    $pos_newsyntax: 'to left';
  } @else if $pos == 'bottom' {
    $pos_newsyntax: 'to top';
  } @else if $pos == 'left' {
    $pos_newsyntax: 'to right';
  }

  @each $g in $args {
    @if ($g != 'top')
			and ($g != 'right')
			and ($g != 'bottom')
			and ($g != 'left')
			and ($g != 'to top')
			and ($g != 'to right')
			and ($g != 'to bottom')
			and ($g != 'to left') {
      $gradient: append($gradient, $g, comma);
    }
  }

  background-image: -webkit-linear-gradient(unquote($pos), $gradient);
  background-image: unquote('linear-gradient(#{$pos_newsyntax}, #{$gradient})');
}

//Utility function to return the relevant colour depending on what type of arrow it is
@function setTriangleColor($direction, $side, $color) {

  @if $direction == "left" and $side == "right"
	or  $direction == "right" and $side == "left"
	or $direction == "down" and $side == "top"
	or $direction == "up" and $side == "bottom" {
    @return $color
  } @else {
    @return "transparent";
  }
}

//==== Example: @include css-triangle ("up", 10px, #fff);
@mixin css-triangle($direction: "down", $size: 20px, $color: #000) {
  width: 0;
  height: 0;
  border-left: $size solid #{setTriangleColor($direction, "left", $color)};
  border-right: $size solid #{setTriangleColor($direction, "right", $color)};
  border-bottom: $size solid #{setTriangleColor($direction, "bottom", $color)};
  border-top: $size solid #{setTriangleColor($direction, "top", $color)};
}