/**
 * Zmienne
 */

$font-arial: Arial,Helvetica Neue,Helvetica,sans-serif;
$font-quicksand: 'Quicksand', sans-serif;
$font-family: $font-quicksand;

// Colors
$color-blue: #0079c3;
$color-green: #4ed5ad;
$color-red: #ee3a43;
$color-dark-blue: #041f46;
$color-lightgrey: #e5e5e5;

$bg-color: #FFF;
$text-color: $color-dark-blue;
$border-color: $color-lightgrey;
$eura7-logo-color-blue: #007AC2;
$eura7-logo-color-red: #EF3E42;
$homeslider-text-gradient-color-1: #00ff8a;
$homeslider-text-gradient-color-2: #bfe7ff;
$homeslider-text-gradient-color-3: #FFF;

// Transitions
$transition-default-duration: 0.3s;
$transition-default-easing: ease-in-out;

// Paths
$gfx-path: "../img";
$fonts-path: "../fonts";

$header-height: 90px;
$header-height-lg: 130px;
$footer-height: 60px;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1242px
);